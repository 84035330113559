import { Modal } from 'react-bootstrap';

const Error = (props) => {
    const { closeResponse, errorMessage, isTranslated } = props;

    return (
        <div className='flash-message-outer'>
            <div className='flash-message-background'></div>
            <div className='flash-message-container flash-message-success'>
                <div>
                    <div className='flash-message-icon-div'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" className='flash-message-success-icon' viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <div className='flash-message-body'>
                        <div className='flash-message-title'>
                            {
                                isTranslated ? 'Kesalahan' : 'Error'
                            }
                        </div> <br/>
                        <div className='flash-message-info'>
                            {
                                isTranslated 
                                ? 'Kesalahan di server, Silakan coba lagi!'
                                : 'Error in server, Please try again!'
                            }
                        </div>
                    </div>
                </div>
                <div className='flash-message-close' onClick={closeResponse}>
                    {
                        isTranslated ? 'Tutup' : 'Close'
                    }
                </div>
            </div>
        </div>
    );
    return (
        <div className='flash-message-outer'>
            <div className='flash-message-container'>
                <div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className='flash-message-success-icon' width="24" height="24" fill="green" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                    </div>
                    <div className='flash-message-body'>
                        <div className='flash-message-title'>Success</div> <br/>
                        <div className='flash-message-info'>Your account has been saved</div>
                    </div>
                </div>
                <div className='flash-message-close' onClick={closeResponse}>
                    CLOSE
                </div>
            </div>
        </div>
    );
    return ( 
        <div>
            <Modal 
                show={true}
                onHide={closeResponse}
                backdrop="static"
                keyboard={false}
                className="resmodal"
            >
                <Modal.Header>
                    <div className='icon-box danger_backcolor' onClick={closeResponse}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" className='bi bi-x' viewBox="0 0 16 16">
                            <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/>
                        </svg>
                    </div>
                    <Modal.Title className='w-100' align="center">
                        {
                            errorMessage.head !== undefined ? errorMessage.head : 'Sorry!'
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <p className="mbody_text">
                        {
                            errorMessage.body !== undefined ? errorMessage.body : 'Unable to process data, please try again!'
                        }
                    </p>
                </Modal.Body>
            </Modal>
        </div>
     );
}
 
export default Error;