import React from 'react'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Grid,  } from 'react-loader-spinner'

const FullPageLoader = () => {
  return (
    <div className='fp-container'>
        {/* <img src={require('../images/lazada_icon.png')} className='fp-loader' alt="loading..." height="75" /> */}
        <div className='fp-loader'>
            {/* <h6>Please wait while it's fetching data...</h6> */}
            <Grid
                color="#1c1b4b"
                height={70}
                width={70}
                ariaLabel='loading'
            />
        </div>
    </div>
  )
}

export default FullPageLoader