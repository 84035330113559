import { Modal, Button } from 'react-bootstrap';

const Message = (props) => {
    const { message, closeMessage, title, isTranslated } = props;
    return (
        <div className='flash-message-outer'>
            <div className='flash-message-background'></div>
            <div className='flash-message-container flash-message-info'>
                <div>
                    <div className='flash-message-icon-div'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="blue" className='flash-message-success-icon' viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                    </div>
                    <div className='flash-message-body'>
                        {/* <div className='flash-message-title'>
                            {
                                isTranslated ? 'Konfirmasi data' : 'Data Confirmation'
                            }    
                        </div> <br/> */}
                        <div className='flash-message-info'>
                            { 
                                message 
                                 ? message
                                 : ( isTranslated ? 'Silakan masukkan Email atau Nomor Telepon untuk melanjutkan' : 'Please enter Email or Phone Number to proceed') 
                            }
                        </div>
                    </div>
                </div>
                <div className='flash-message-close' onClick={closeMessage}>
                    <span className='flash-message-close-text'>
                        {
                            isTranslated ? 'Tutup' : 'Close'
                        }
                    </span>
                </div>
            </div>
        </div>
    );
    return ( 
        <div>
            <Modal 
                show={true}
                onHide={closeMessage}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{ title === null ? 'Data Confirmation' : title }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { message }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={closeMessage}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
     );
}
 
export default Message;